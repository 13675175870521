.btn-copy {
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  border-radius: 25px;
  color: #fcfcfc;
  border: transparent;
  width: 100%;
  background: #7F00FF;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #E100FF, #7F00FF);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #E100FF, #7F00FF);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btn-verify-close {
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  height: 45px;
  border-radius: 25px;
  color: #000000;
  border: transparent;
  width: 70%;
  background: #ada996;
  background: -webkit-linear-gradient(to right,
      #eaeaea,
      #dbdbdb,
      #f2f2f2,
      #ada996);
  background: linear-gradient(to right, #eaeaea, #dbdbdb, #f2f2f2, #ada996);
}
.upload-image {
  border-style: dotted;
  border-color: #D989B5;
  padding: 10% 5%;
}
.image_slip {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 5%;
  border-radius: 5px;
}
.custom-modal-statement>div>.modal-content {
  background: transparent;
  border: none;
}
.custom-modal-statement>div>.modal-content>.modal-body {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #222222;
  padding: 0 0;
}
.custom-modal-statement>div>.modal-content>.modal-header {
  // background: #7F00FF;
  // background: -webkit-linear-gradient(to right, #E100FF, #7F00FF);  
  // background: linear-gradient(to right, #E100FF, #7F00FF); 
  border-bottom: none !important;
  color: #fcfcfc;
}
.color-white {
  color: #fcfcfc;
}
.alert-danger-custom1 {
  color: #656565 !important;
  background-color: #fff4cf !important;
  border-color: transparent !important;
  border-radius: 12px !important;
  font-size: 13px !important;
}
.v-card {
  background-size: cover;
  max-width: 100% !important;
  // box-shadow: 1px 1px #0e0e0e !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  touch-action: manipulation;
  width: 100% !important;
  border: none;
  background-color: transparent !important;
}
.input_withdraw_max {
  height: 40px;
  padding: 10px 0px 10px 10px;
  background: #ffffff9c !important;
}
.btn_hover_withdraw {
  background-size: 300% 100%;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 2px 10px 0 #717171bf;
}
.btn_hover_withdraw:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 0.75);
}
.btn_hover_withdraw:focus {
  outline: none;
}
.div_bank_withdraw {
  display: flex;
  align-items: center;
  color: #fff;
  background: #000;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 1rem;
}
.div_img_bank_withdraw {
  min-width: 30px;
  width: 30px;
}
.div_img_bank_withdraw img {
  border-radius: 50%;
}
.spn_fname_lastname {
  margin-left: 1rem;
}
.div_icon_withdraw img {
  width: 5rem;
}
.div_container_infomation {
  background: #0006;
  border: 1px solid #fff;
  padding: 15px;
  border-radius: 0.5rem;
  color: #fff;
}
.div_sec_indomation_withdraw {
  & h2 {
    font-size: 16px;
  }
  & .p_credit {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  & .p_turn {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}
.btn_withdraw {
  width: 100%;
  background: #009f55;
  border: none;
  border-radius: 0.5rem;
}
.___ {
  border: 5px solid #ebebeb;
  border-radius: 10px;
  margin: 1rem auto;
}
.div_txt_infomation {
  font-size: 14px;
  text-align: center;
  & .p_turn {
    margin-bottom: 0rem;
  }
}
@media only screen and (max-width: 768px) {
  .header-slip {
    font-size: 18px;
  }
  .btn-verify-close {
    font-size: 14px;
    font-weight: bold;
    width: 80%;
  }
}