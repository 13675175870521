.div_container_main_register_v2 {
    width: 30% !important;
    margin: 8rem auto 5rem auto;
}
.grid_title_text_register {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.grid_container_register_v2>div>p {
    color: #fff;
}
.box_register_input {
    width: 100%;
    margin-top: 1rem;
}
.card_v2 {
    align-items: center !important;
    padding: 10px;
    color: #fff;
    background-size: cover;
    max-width: 100% !important;
    // box-shadow: 1px 1px #0e0e0e !important;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    touch-action: manipulation;
    width: 100% !important;
    border: none;
    background-color: transparent !important;
    border-radius: 1.5rem;
    padding: 30px 30px 15px 30px;
    margin-top: 0.5rem;
    box-shadow: 0 4px 15px 0 #313131;
    transition: transform .5s;
}
.card_v2:hover {
    transform: scale(1.1);
}
.div_img_bank_in_card {
    float: inline-end;
    & img {
        width: 42px;
        // border-radius: 50%;
    }
}
.div_img_bank_in_card_default {
    float: inline-end;
    & img {
        width: 70px;
    }
}
.grid_card_number {
    margin-top: 3rem !important;
}
.txt_none_focus {
    color: #b1b1b1;
}
.txt_focus {
    font-weight: bold;
    letter-spacing: 1px;
}
.btn_chk_register_v2 {
    margin-top: 1rem;
    width: 100% !important;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    height: auto;
    border-radius: 0.25rem;
    color: #fff;
    border: transparent;
    background: linear-gradient(to right, #3285ff, #3285ff);
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.alert_des {
    background-color: transparent !important;
    color: #ff0018 !important;
    text-align: left;
    align-items: left;
}
.div_form_register {
    color: #b9b9b9;
}
.div_form_register>div>label {
    font-size: 16px;
}
.btn_submit_register_v2 {
    margin-top: 0.5rem;
    width: 100% !important;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    height: auto;
    border-radius: 0.5rem;
    color: #fcfcfc;
    border: transparent;
    background: linear-gradient(to right, #00c168, #6f42c1);
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.btn-hover:focus {
    outline: none;
}
.btn-hover.btn_submit_register_v2 {
    background-image: linear-gradient(to right,
            #0ba360,
            #3cba92,
            #30dd8a,
            #2bb673);
    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}
.btn-hover.btn_chk_register_v2 {
    background-image: linear-gradient(to right,
            #25aae1,
            #4481eb,
            #04befe,
            #3f86ed);
    box-shadow: 0 2px 5px 0 #4184eabf;
}
.select_bank_mui {
    font-size: 15px;
    width: 100%;
    height: 38px !important;
}
.select_bank_mui>div {
    padding: 3px 11px !important;
    height: 33px !important;
    border-radius: 0.375rem !important;
    background: #fff;
    align-items: center;
    display: flex;
}
.btn_check_info {
    border: none;
    width: 100% !important;
    background: #e9b033;
    color: #fff;
    font-weight: 600;
}
@media only screen and (max-width: 768px) {
    .div_container_main_register_v2 {
        padding: 15px;
        width: 100% !important;
        margin-top: 5rem !important;
    }
    .grid_card_number {
        margin-top: 2rem !important;
    }
    .grid_container_register_v2 {
        width: 100% !important;
    }
}