$primary-color: #2c372a;
.btn-credit {
  background: #ece9e6;
  background: -webkit-linear-gradient(to right, #ffffff, #ece9e6);
  background: linear-gradient(to right, #ffffff, #ece9e6);
  box-shadow: rgba(0, 0, 0, 0.25) 0 5px 5px;
}
.list-menu-mobile {
  background: #ff8008;
  background: -webkit-linear-gradient(to bottom, #ffc837, #ff8008);
  background: linear-gradient(to bottom, #ffc837, #ff8008);
}
.navbar_custom {
  // height: 80px;
}
.div_txt_navbar {
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
  }
}
.div_txt_navbar2 {
  display: flex;
  align-items: center;
  margin-left: 15px;
  span {
    font-size: 16px;
  }
}
.x_hamburger {
  margin-left: 24px;
  margin-top: 2px;
  width: 25px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  background-color: transparent !important;
  border: none;
}
.x_hamburger span {
  width: 70%;
  display: block;
  position: absolute;
  height: 3px;
  // background: #999;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  animation: x-hamburger-wave .4s ease-out infinite alternate;
}
.x_hamburger span:first-child {
  top: 0;
  -webkit-animation: x-hamburger-wave .4s ease-out infinite alternate;
  animation: x-hamburger-wave .4s ease-out infinite alternate
}
.x_hamburger span:first-child,
.x-hamburger span:nth-child(2) {
  -webkit-transform-origin: left center;
  transform-origin: left center
}
.x_hamburger span:nth-child(2) {
  top: 9px;
  -webkit-animation: x-hamburger-wave .4s ease-out .3s infinite alternate;
  animation: x-hamburger-wave .4s ease-out .3s infinite alternate
}
.x_hamburger span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-animation: x-hamburger-wave .4s ease-out .6s infinite alternate;
  animation: x-hamburger-wave .4s ease-out .6s infinite alternate
}
.header_sec {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // background-color: #000b;
}
.wrap_header {
  display: flex;
  flex-wrap: wrap;
}
.wrap_header .header_left {
  width: 80%;
  position: relative;
  z-index: 10;
  height: 95px;
}
.wrap_item_header_left {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.flex {
  display: flex;
}
.wrap_header .header_left .wrap_item_header_left .box_logo img {
  max-width: 80px;
  height: auto;
}
.wrap_header .header_left .wrap_item_header_left .box_logo {
  padding-top: 0.5rem;
  margin-left: 2rem;
  height: 80px;
  width: 22%;
  display: flex;
  align-items: center;
}
.div_header_login_register {
  padding-right: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.div_header_login {
  width: 300px;
  margin-bottom: 1.25rem;
}
.div_header_register {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  width: 300px;
  margin-bottom: 1.25rem;
}
.wrap_header .header_right {
  width: 20%;
  position: relative;
  z-index: 10;
  max-height: 72px;
}
.div_wrap_item_header_right {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  // margin-top: 0.5rem;
}
.inner_wrap_item_header_right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.box_bag_header {
  width: 13%;
  text-align: center;
  margin-left: 1rem;
  position: relative;
}
.box_bag_header img {
  width: 100%;
  margin-bottom: -5px;
}
.box_bag_header .txt_d {
  // position: absolute;
  bottom: -80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  white-space: nowrap;
  font-size: 14px;
  text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
}
.box_promotion_header {
  width: 13%;
  text-align: center;
  position: relative;
}
.box_promotion_header img {
  width: 100%;
  margin-bottom: -5px;
}
.box_promotion_header .txt_d {
  // position: absolute;
  bottom: -80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  white-space: nowrap;
  font-size: 14px;
  text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
}
.box_hamburger {
  width: 13%;
  text-align: center;
  position: relative;
}
.box_hamburger span {
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
}
.ham {
  margin-left: 0rem;
  margin-top: 0.4rem;
}
.marquee_sec {
  height: 22px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 85%;
  height: 25px;
  background: linear-gradient(90deg, #15151e 0%, #081935 50%, #15151e 100%);
  z-index: 9;
  overflow: hidden;
}
.inner_section {
  display: flex;
  position: relative;
  height: 100%;
}
.txt_head_marquee {
  color: #e9b033;
  background: #000;
  padding: 3px 0.5rem 3px 6.5rem;
  z-index: 2;
  width: auto;
}
.btn_login_header {
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  border-radius: 12px;
  background: linear-gradient(180deg, white, #ffffff) !important;
  color: #000;
  border: transparent;
  width: 100%;
}
.btn_register_header {
  color: #f1bb09;
  font-size: 14px;
  background: transparent;
  border: 2px solid white;
  border-radius: 12px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-header-left-mb {
  display: none;
}
.bg-header-right-mb {
  display: none;
}
.box_economy {
  width: 78%;
  display: flex;
  margin-top: 0.2rem;
}
.box_username {
  width: 40%;
  margin-top: -5px;
  order: 1;
}
.div_card_rank {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 0.5rem;
}
.div_card_rank_sec_1 {
  width: 10%;
}
.div_card_rank_sec_1 img {
  width: 100%;
  height: auto;
}
.div_card_rank_sec_2 {
  width: 90%;
}
.div_card_rank_sec_2_1 {
  display: flex;
  flex-direction: column;
}
.text_username {
  color: #e9b033;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
  text-shadow: 0px 1px 0px #000;
}
.inner_economy {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
  order: 2;
}
.box_amount_header {
  width: 200px;
  height: 41px;
  display: flex;
  padding: 2px;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  background: var(--black, rgba(1, 6, 0, .7058823529));
  box-shadow: 0 4px 2px #00000040 inset, 0 -4px 2px #00000040 inset;
}
.b_coin_rank {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.b_coin_rank span {
  margin-left: 5px;
}
.b_coin_rank img {
  width: 15px;
  height: auto;
}
.b_coin_rank_last {
  width: 15px !important;
  height: auto;
}
.b_coin {
  transform-style: preserve-3d;
  animation: spin 2.5s infinite linear;
}
.b_coin img {
  width: 32px;
  height: auto;
  animation: brightness 2.5s infinite linear;
}
.box_amount_header p {
  margin-bottom: 0;
  color: #e9b033;
  & span {
    font-size: 18px;
    font-weight: 600;
  }
}
.box_amount_header span {
  color: rgb(233, 176, 51);
  font-size: 35px;
  cursor: pointer;
}
.xicon {
  width: 1em;
  height: 1em;
  display: inline-flex;
}
.box_gem_header {
  margin-left: 1.5rem;
  width: 200px;
  height: 41px;
  display: flex;
  padding: 2px;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  background: var(--black, rgba(1, 6, 0, .7058823529));
  box-shadow: 0 4px 2px #00000040 inset, 0 -4px 2px #00000040 inset;
}
.gem_container {
  position: relative;
}
.gem_container img {
  width: 22px;
  height: auto;
  margin-left: 5px;
}
.sparkles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sparkles .one {
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 1;
  font-size: 11px;
  animation: shine .5s linear infinite;
}
.sparkles .two {
  position: absolute;
  bottom: -5px;
  right: -5px;
  z-index: 1;
  font-size: 9px;
  animation: shine .5s linear infinite .25s;
}
.box_gem_header p {
  margin-bottom: 0;
  color: #e9b033;
  font-size: 18px;
  font-weight: 600;
}
.box_gem_header span {
  color: rgb(233, 176, 51);
  font-size: 35px;
  cursor: pointer;
}
.box_bag_header a {
  text-decoration: none !important;
}
.box_promotion_header a {
  text-decoration: none !important;
}
.drawer_custom {
  width: 300px !important;
  color: #fff;
  height: 92vh !important;
  margin-top: 8vh;
  padding: 0 1.5rem !important;
  // background: linear-gradient(180deg, #15151e 0%, #081935 100%) !important;
}
.div_drawer_custom {
  padding: 0.8rem 0rem !important;
}
.div_drawer_custom>.div_svg_close {
  display: flex;
  justify-content: end;
  cursor: pointer;
}
.img_card_drawer {
  width: 80px;
}
.grid_container_drawer {
  border: 1px solid #fff;
  border-radius: 0.5rem;
  padding: 10px;
  margin-top: 1rem;
}
.grid_container_drawer>div {
  font-size: 16px;
  font-weight: 600;
}
.txt_align_right {
  text-align: right;
}
.grid_container_drawer_two {
  text-align: center;
  margin-top: 0.1rem !important;
}
.drawer_deposit {
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(180deg, #64d937 0%, #01821e 100%);
  height: 40px;
  font-size: 16px;
  border: none;
}
.drawer_withdraw {
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(180deg, #c10a09 0%, #970f0f 100%);
  height: 40px;
  font-size: 16px;
  border: none;
}
.hr_drawer {
  border-top: 1px solid #fff;
  opacity: 90%;
}
.footer_drawer a {
  position: relative;
  display: flex;
  text-decoration: none;
}
.footer_drawer a img {
  width: 100%;
  height: auto;
}
.footer_drawer a span {
  position: absolute;
  top: 0;
  left: 0;
  right: 28%;
  bottom: 0;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  font-size: 14px;
}
.div_drawer_ui_li ul {
  height: 380px;
  padding: 0px;
  margin: 0px;
  text-align: left;
  overflow: hidden;
  overflow-y: scroll;
}
.div_drawer_ui_li ul li {
  list-style: none;
  // margin-top: 2rem;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.div_drawer_ui_li ul li span {
  margin-left: 0.5rem;
}
.progress_bar_header_rank {
  background: #fff !important;
}
.progress_bar_header>div {
  // width: auto !important;
}
.div_only_sanook {
  border-bottom: 4px solid #f70000 !important;
  margin-bottom: 1rem !important;
}
@media only screen and (max-width: 768px) {
  .wrap_header .header_right {
    max-height: 68px;
  }
  .marquee_sec {
    top: 69px !important;
  }
  .inner_section div {
    font-size: 14px;
  }
  .div_drawer_ui_li ul {
    height: 450px;
  }
  .footer_drawer a span {
    right: 20%;
  }
  .div_drawer_ui_li ul li {
    font-size: 14px;
  }
  .div_rank_header {
    font-size: 14px;
  }
  .txt_align_right {
    font-size: 14px;
  }
  .img_card_drawer {
    width: 60px;
  }
  .div_drawer_custom {
    padding: 0.8rem 0 !important;
  }
  .drawer_custom {
    width: 230px !important;
    padding: 0 0.8rem !important;
  }
  .progress_bar_header {
    width: auto;
    height: auto;
  }
  .box_gem_header p {
    font-size: 12px !important;
  }
  .sparkles .one {
    font-size: 8px;
  }
  .sparkles .two {}
  .box_gem_header img {
    width: 12px;
  }
  .box_gem_header {
    margin-left: 0.5rem !important;
    width: 80px !important;
    height: 22px !important;
  }
  .xicon {
    font-size: 20px !important;
  }
  .box_amount_header p {
    & span {
      font-size: 12px;
    }
  }
  .box_amount_header p {
    font-size: 12px;
  }
  .box_amount_header img {
    width: 18px !important;
  }
  .box_amount_header {
    width: 115px !important;
    height: 22px !important;
  }
  .inner_economy {
    order: 1 !important;
    margin-top: 0.2rem !important;
    width: 100% !important;
  }
  .text_username {
    display: none;
    margin-left: 0.1rem !important;
  }
  .div_card_rank_sec_2 {
    width: 85%;
    display: grid;
    align-items: center;
  }
  .div_card_rank_sec_1 img {
    width: 22px;
    margin-left: 7px;
  }
  .div_card_rank_sec_1 {
    width: 15%;
  }
  .box_username {
    order: 2 !important;
    width: 100% !important;
  }
  .box_economy {
    // width: 72% !important;
    width: 68% !important;
    margin-top: 0.2rem !important;
    flex-direction: column;
  }
  .btn_register_header {
    font-size: 13px;
    height: 35px;
  }
  .btn_login_header {
    font-size: 13px;
    height: 35px;
  }
  .div_header_login_register {
    padding-right: .8rem;
  }
  .wrap_header .header_left .wrap_item_header_left .box_logo {
    margin-left: 10px;
    width: 28%;
  }
  .div_header_register {
    width: 100px;
  }
  .div_header_login {
    width: 100px;
  }
  .inner_wrap_item_header_right {
    margin-top: 1rem;
  }
  .box_promotion_header {
    display: none;
  }
  .box_bag_header {
    display: none;
  }
  .txt_head_marquee {
    padding: 0px 0.5rem 3px 3.5rem;
    width: 43%;
  }
  .bg-header-right {
    display: none;
  }
  .bg-header-left {
    display: none;
  }
  .bg-header-left-mb {
    display: block;
  }
  .bg-header-right-mb {
    display: block;
  }
  .x_hamburger {
    margin-top: 3px;
    width: 22px;
    height: 22px;
  }
  .list-menu {
    display: none;
  }
  .navbar_custom {
    height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .box_hamburger {
    margin-top: 0.8rem;
  }
  .box_promotion_header {
    display: none;
  }
  .box_bag_header {
    display: none;
  }
  .div_in_a_category img {
    width: 50px;
  }

  .btn_login_header {
    font-size: 13px;
  }
  .btn_register_header {
    font-size: 13px;
  }
  .div_header_login {
    width: 100px;
  }
  .div_header_register {
    width: 100px;
  }
  .div_header_login_register {
    padding-right: .8rem;
  }
  .wrap_header .header_left .wrap_item_header_left .box_logo {
    margin-left: 10px;
    width: 28%;
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .list-menu {
    display: none;
  }
}
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-85:before {
  content: "";
  background: linear-gradient(45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}
@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

