// .logo-login {
//     -webkit-animation: mover 1s infinite alternate;
//     animation: mover 1s infinite alternate;
//   }
.img_logo_login {
    width: 190px;
    height: auto;
    object-fit: contain;
}
.btn_login {
    font-size: 16px;
    font-weight: 600;
    height: 35px;
    border-radius: 12px;
    background: linear-gradient(180deg,white,#ffffff) !important;
    color: #000;
    border: transparent;
    width: 100%;
}
.btn_login:hover {
    color: #e1e1e1;
    transition-duration: 0.1s;
}
.btn_register {
    font-size: 16px;
    background: transparent;
    border: 2px solid white;
    border-radius: 12px;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p_contact_admin {
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
    & span {
        color: #e9b033;
        text-decoration: underline;
        cursor: pointer;
    }
}
.custom-modal-login>div>.modal-content {
    background: transparent;
    border: none;
}
.custom-modal-login>div>.modal-content>.modal-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: linear-gradient(180deg,#3e3e3e 0%,#010600 100%);
    padding: 1.5rem;
    border: 3px solid #adadad;
    animation: animatedgradient 3s ease alternate infinite;
}
.custom-modal-login>div>.modal-content>.modal-header {
    background: #ff8008;
    background: -webkit-linear-gradient(to bottom, #ffc837, #ff8008);
    background: linear-gradient(to bottom, #ffc837, #ff8008);
    border-bottom: none !important;
    color: #fcfcfc;
}
.img_bank_register {
    width: 100%;
    height: auto;
    max-width: 100%;
    cursor: pointer;
}
.div_bank_register {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    margin: 0 0 1rem;
    background: rgba(0,0,0,.4);
    box-shadow: 0 2px 2px #00000040 inset, 0 -2px 2px #00000040 inset;
    border-radius: 8px;
}
.btn_back_register {
    border: none;
    width: 100% !important;
    background: #fff !important;
    color: #E9B033 !important;
    font-weight: 600;
}
.btn_next_register {
    border: none;
    width: 100% !important;
    background: #fff;
    color: #000;
    font-weight: 600;
}
.stepper_ {
    background-color: transparent !important;
    padding: 10px !important;
}
.div_register_aff {
    width: 765px;
    padding: 10px;
    margin: 7rem auto 3rem auto;
}
@media only screen and (max-width: 768px) {
    .div_register_aff {
        width: 100%;
    }
    .img_logo_login {
        width: 140px;
    }
    .custom-modal-login>div>.modal-content {
        width: 90%;
        margin: 0 auto;
    }
}
