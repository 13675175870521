.footer-header {
  // background: -webkit-linear-gradient(#da22ff, #9733ee);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // font-weight: bolder;
  color: #bf94ff;
}
.info_commission {
  font-size: 12px;
  color: #ff4747;
}
.a_footer_img {
  display: flex;
  padding: 0px;
  justify-content: center;
}
.img_logo_footer {
  max-width: 100%;
  width: 130px;
  height: auto;
  border-radius: 5px;
}
.div_text1_info_footer>h1 {
  color: #fff;
  margin-bottom: 0.2rem;
  text-align: center;
  font-size: 18px;
}
.div_text1_info_footer>p {
  color: #fff;
  margin-bottom: 0.2rem;
  text-align: center;
}
.txt_title_provider_footer {
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(180deg, #f2d638 0%, #e9b033 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  margin-bottom: 1.5rem;
}
.div_container_provider_footer {
  justify-content: center;
}
.div_img_provider_footer {
  width: 100%;
  background: #ffffff26;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px 5px;
  border-radius: 0.75rem;
}
.img_provider_footer {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.img_bank_footer {
  max-width: 100%;
  width: 60px;
  height: auto;
}
.container_footer {
  // padding-bottom: 2rem;
}
.div_sec_footer {
  width: 60%;
  margin: 0 auto 2.5rem auto;
}
.footer_copyright {
  text-align: center;
  padding: 1rem;
  color: #fff;
}
.active_contact {
  transform: translate(0);
  transition: all 1s ease-in-out;
}
.box_contact {
  position: fixed;
  top: 65%;
  right: 0;
  border-radius: 12px 0 0 12px;
  background: linear-gradient(180deg, rgba(62, 62, 62, .5411764706) 0%, rgba(10, 10, 10, .5725490196) 100%);
  padding: 1rem 0.5rem 1rem 1.3rem;
  transform: translate(0);
  animation: slide 2s ease-in;
  z-index: 5;
}
.close_contact {
  transform: translate(3.5rem);
  transition: all 1s ease-in-out;
}
.box_contact .open_contact {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -15px;
  margin: auto;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  border: none;
}
.close_contact svg {
  rotate: 180deg;
}
.div_slice_contact {
  display: flex;
  flex-direction: column;
}
.box_contact a:first-child img {
  margin-bottom: 10px;
}
.box_contact a:nth-child(2) img {
  margin-bottom: 10px;
}
.box_contact a img {
  width: 40px;
}
.wrap_footer_float {
  display: none;
}
@media only screen and (max-width: 768px) {
  .wrap_footer_float {
    display: block;
  }
  .box_contact .open_contact {
    left: -25px;
  }
  .hide_footer {
    transform: translateY(10rem)
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float:nth-child(3) {
    animation: zoom 1.5s infinite !important;
    width: 30%
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float:nth-child(3) p {
    display: none;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float p {
    position: absolute;
    bottom: -17px;
    left: 0;
    right: 0;
    margin: -10px auto 0;
    color: #fff;
    text-align: center;
    text-shadow: 0px 2px 0px #000;
    font-family: Kanit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float:nth-child(3) img {
    margin-top: -5px;
    width: 90%;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float:nth-child(3) span {
    display: flex;
    justify-content: center;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float span img {
    margin: 8px auto 0;
    width: 80%;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float {
    width: 17.5%;
    text-align: center;
    position: relative;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 2px 5px;
    height: 125%;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float p {
    position: absolute;
    bottom: -17px;
    left: 0;
    right: 0;
    margin: -10px auto 0;
    color: #fff;
    text-align: center;
    text-shadow: 0px 2px 0px #000;
    font-family: Kanit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float .wrap_menu_foot_float .item_menu_foot_float:first-child p {
    position: absolute;
    bottom: -17px;
    left: 0;
    right: 0;
    margin: -10px auto 0;
    color: #fff;
    text-align: center;
    text-shadow: 0px 2px 0px #000;
    font-family: Kanit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .wrap_footer_float .box_footer_float .inner_box_footer_float {
    border-radius: 32px;
  }
  .wrap_footer_float .box_footer_float {
    padding: 2px;
    border-radius: 32px;
  }
  #footer_main {
    transition: all .8s ease-in;
  }
  .wrap_footer_float {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 75.52%);
  }
  .div_img_provider_footer {
    padding: 13px 0px;
  }
  .div_sec_footer {
    width: 90%;
  }
  .img_logo_footer {
    width: 120px;
  }
  .info_commission {
    font-size: 10pxs;
  }
}