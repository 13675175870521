@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  font-family: Kanit, sans-serif !important;
}
@import "custom-bar";
@import "game";
@import "footer";
@import "statement";
@import "custom-promotion";
@import "custom";
@import "custom-lotto";
@import "custom-register";
@import "custom-animetion";
@import "custom-login-register";
@import "search";
@import "custom-deposit";

html,
body {
  // background: #0A1933;
  height: 100%;
  margin: 0;
}