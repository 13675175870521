.div_container_game {
  border-bottom: 2px solid #e5e5e5;
}
.section_content {
  margin: 0 auto;
  margin: 1.5vw 6vw;
  overflow: hidden;
}
.block_game_category {
  display: flex;
  flex-wrap: wrap;
}
.box_other_category {
  width: 40%;
  height: 200px;
}
.div_other_category {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.box_ctg_1 {
  cursor: pointer;
  position: relative;
  width: 33.3%;
  height: 100%;
}
.img_w100_h100 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.newgame_icon_1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 130px;
}
.lotto_js_icon_1 {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 150px;
}
.newgame_icon_2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  margin: 0 auto;
  width: 80px;
}
.lotto_js_icon_2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 38px;
  margin: 0 auto;
  width: 95px;
}
.newgame_icon_3 {
  position: absolute;
  left: 40px;
  bottom: 40px;
  margin: 0 auto;
  width: 65px;
  animation: wobble infinite 3.5s ease-in-out;
}
.lotto_js_icon_3 {
  position: absolute;
  left: 35px;
  bottom: 40px;
  margin: 0 auto;
  width: 45px;
  animation: tada ease-in 1s infinite alternate;
  // animation: wobble infinite 3.5s ease-in-out;
}
.newgame_icon_4 {
  position: absolute;
  right: 40px;
  bottom: 45px;
  margin: 0 auto;
  width: 65px;
  animation: wobble infinite 4s ease-in;
}
.lotto_js_icon_4 {
  position: absolute;
  right: 35px;
  bottom: 40px;
  margin: 0 auto;
  width: 45px;
  animation: tada ease-in 1s infinite alternate;
  // animation: wobble infinite 4s ease-in;
}
.newgame_icon_5 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  width: 70px;
  animation: card infinite 2s ease-in;
}
.lotto_js_icon_5 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  width: 70px;
  animation: card infinite 2s ease-in;
}
.newgame_icon_6 {
  position: absolute;
  left: 50px;
  bottom: 20px;
  margin: auto;
  width: 50px;
  animation: blink_game .2s infinite alternate;
}
.lotto_js_icon_6 {
  position: absolute;
  left: 40px;
  bottom: 20px;
  margin: auto;
  width: 45px;
  animation: blink_game .2s infinite alternate;
}
.txt_newgame {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(180deg, #fff 43.75%, #ff8587 59.86%, #fffdfe 75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 600;
}
.icon_game_right {}
.icon_game_left {}
.box_ctg_2 {
  position: relative;
  cursor: pointer;
  width: 33.3%;
  height: 100%;
}
.gamehot_icon_1 {
  filter: drop-shadow(0px 0px 18px #d45bff);
  width: 100%;
  height: 100%;
}
.gamehot_icon_2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: auto;
  width: 110px;
  animation: zoomIn infinite 3.5s ease-in-out;
}
.gamehot_right_icon_1 {
  position: absolute;
  right: 60px;
  top: 20px;
  margin: auto;
  width: 30px;
  animation: top-bottom 5s infinite ease-in;
}
.gamehot_right_icon_2 {
  position: absolute;
  top: 50px;
  right: 30px;
  margin: auto;
  width: 45px;
  animation: top-bottom 4s infinite ease-in;
}
.gamehot_right_icon_3 {
  position: absolute;
  right: 50px;
  bottom: 25px;
  margin: auto;
  width: 40px;
  animation: top-bottom 3s infinite ease-in;
}
.gamehot_left_icon_1 {
  position: absolute;
  left: 50px;
  top: 10px;
  margin: auto;
  width: 60px;
  animation: top-bottom 5s infinite ease-in;
}
.gamehot_left_icon_2 {
  position: absolute;
  top: 60px;
  left: 40px;
  margin: auto;
  width: 35px;
  animation: top-bottom 4s infinite ease-in;
}
.gamehot_left_icon_3 {
  position: absolute;
  left: 50px;
  bottom: 30px;
  margin: auto;
  width: 40px;
  transform: rotate(180deg);
  animation: top-bottom 3s infinite ease-in;
}
.gamehot_icon {
  position: absolute;
  left: 40px;
  top: 10px;
  margin: auto;
  width: 65px;
  -webkit-clip-path: polygon(95% 0, 100% 0, 100% 100%, 95% 100%);
  clip-path: polygon(95% 0, 100% 0, 100% 100%, 95% 100%);
  animation: square-in-top-right 1s infinite;
  transition: all .5s ease;
}
.box_ctg_2 .txt {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin-left: -10px;
  background: linear-gradient(180deg, #fff 43.75%, #ecc0ff 59.37%, #fffdfe 75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}
.box_ctg_3 {
  position: relative;
  cursor: pointer;
  width: 33.3%;
  height: 100%;
}
.img_newgame {
  // filter: drop-shadow(0px 0px 7px #AE2222);
}
.favgame_icon_1 {
  // filter: drop-shadow(0px 0px 7px #0158D0);
}
.favgame_icon_2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  width: 110px;
}
.favgame_icon_3 {
  position: absolute;
  right: 40px;
  top: 15px;
  margin: auto;
  animation: swing ease-in-out 1s infinite alternate;
}
.favgame_icon_4 {
  position: absolute;
  left: 65px;
  top: 15px;
  margin: auto;
  width: 80px;
}
.favgame_icon_5 {
  position: absolute;
  left: 55px;
  bottom: 48px;
  margin: auto;
  animation: tada ease-in 1s infinite alternate;
  width: 50px;
}
.box_ctg_3 .txt {
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(180deg, #fff 43.75%, #a6d5ff 59.86%, #fffdfe 75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 600;
}
.wrap_category_game {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 1rem;
}
.item_category_game {
  width: 24%;
  background-size: 100% 100%;
  position: relative;
  cursor: pointer;
}
.item_category_game .bg-btn-game1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.div_in_a_category {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 1;
  color: #fff;
}
.div_in_a_category img {
  position: absolute;
  top: 0;
  left: 10%;
  width: 80px;
  margin-right: 2rem;
  transition: .5s;
}
.div_in_a_category span {
  margin-left: 6rem;
}
.item_category_game:hover .inner-bg-btn {
  fill: url(#gradient-active)
}
.item_category_game:hover .div_in_a_category {
  color: var(--primary-font-color);
  font-weight: 600
}
.item_category_game:hover .div_in_a_category img {
  filter: drop-shadow(0px 2px 2px #000);
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  transition: 0.8s ease;
  animation: zoom-in-zoom-out 1.2s ease infinite;
}
.active_category_game {
  .inner-bg-btn {
    // fill: url(#gradient-active)
  }
  .div_in_a_category {
    // color: var(--primary-font-color);
    font-weight: 600
  }
}
.wrap_list_all_game {}
.item_list_all_game {
  margin: 1rem 0 0.9rem;
}
.div_title_game {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.div_title_game::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: linear-gradient(180deg, #f2d638 0%, #e9b033 100%);
  border-radius: 20px;
}
.div_title_game h2 {
  font-size: 20px;
  color: #e9b033;
  font-weight: 700;
  margin-left: 1.3rem !important;
  margin: 0;
}
.div_title_game span {
  color: #e9b033;
  font-size: 16px;
  font-weight: 600;
}
.div_title_gameV2 {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.div_title_gameV2::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: linear-gradient(180deg, #f70000 0%, #f70000 100%);
  border-radius: 20px;
}
.div_title_gameV2 h2 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin-left: 1.3rem !important;
  margin: 0;
}
.div_title_gameV2 span {
  color: #f70000;
  font-size: 16px;
  font-weight: 600;
}
.gif_fire {
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  z-index: 2;
  height: auto;
  max-width: 100%;
  border-radius: 8px;
}
.fav_game {
  cursor: pointer;
  position: absolute;
  top: -5px;
  left: 95px;
  width: 34px;
  z-index: 2;
  height: auto;
  max-width: 100%;
  border-radius: 8px;
  animation: fadeIn .5s;
  transition: .4s;
}
.fav_game:hover {
  // -webkit-transform: scale(0.9);
  // -ms-transform: scale(0.9);
  // transform: scale(0.9);
  // transition: 0.8s ease;
  animation: zoomIn 1.2s ease;
}
.div_dt_game {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background: linear-gradient(180deg, rgba(0, 22, 60, 0) 0%, rgba(0, 0, 0, .8) 20.51%);
  border-radius: 0 0 1.5rem 1.5rem;
  display: flex;
  align-items: center;
}
.div_dt_game_fire {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background: linear-gradient(180deg, rgba(0, 22, 60, 0) 0%, rgba(0, 0, 0, .8) 20.51%);
  border-radius: 0 0 1.4rem 1.4rem;
}
.div_dt_game p {
  margin: 0 auto;
  font-size: 12px;
  text-align: center;
  text-wrap: nowrap;
  font-weight: 300;
}
.div_dt_game_fire p {
  margin: 0 auto;
  font-size: 12px;
  text-align: center;
  text-wrap: nowrap;
  font-weight: 300;
}
.div_game_title {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
}
.img_game {
  border-radius: 1.5rem;
  // border-radius: 0.25rem;
  cursor: pointer;
  object-fit: cover;
}
.progress_bar_header_game {
  height: 8px;
}
.div_promotion_image_pro_games {
  margin: 2rem auto auto auto;
  // padding: 0px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}
.promotion_image_pro_games {
  width: 100%;
  max-height: 100%;
  border-radius: 35px 35px 0px 0px;
  /* padding: 15px; */
  padding-bottom: 0px;
  cursor: pointer;
}
.text-provider-name-login {
  border-radius: 0px 0px 35px 35px;
  font-size: 10px;
  color: #fff;
  margin: 0 auto;
  height: 30px;
  background: transparent linear-gradient(180deg, #0096e3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.div_key_thai {
  color: #E9B033;
  font-size: 22px;
}
.h4_txt_select_provider {
  color: #E9B033;
  font-size: 22px;
}
.div_uigame_chk {
  color: #E9B033;
  font-size: 22px;
}
.div_container_uigame_notfound {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  min-height: 300px;
}
.div_uigame_chk_notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0008;
  border-radius: 12px;
  width: 100%;
}
.div_uigame_chk_notfound h2 {
  color: #fff;
  font-size: 22px;
}
.div_progress_bar {
  padding: 0px 3px;
}
.div_in_a_category:hover {
  color: #000 !important;
}
.active_tab {
  color: #000 !important;
}
.active_tabV2 {
  color: #fff !important;
}
@media screen and (min-width: 576px) {
  .show-search {
    width: 450px;
  }
}
@media only screen and (max-width: 768px) {
  .box_other_category {
    width: 100%;
    height: auto;
  }
  .wrap_category_game {
    width: 100% !important;
    gap: 2px !important;
    flex-wrap: nowrap !important;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    overflow-x: scroll;
    justify-content: flex-start;
    padding-left: 0rem !important;
  }
  .div_progress_bar {

  }
  .div_container_uigame_notfound {
    margin-top: .5rem !important;
  }
  .div_uigame_chk_notfound h2 {
    font-size: 18px;
  }
  .div_uigame_chk {
    font-size: 18px;
  }
  .div_search_game {
    width: 40px !important;
    height: 40px !important;
  }
  .h4_txt_select_provider {
    font-size: 18px;
  }
  .div_key_thai {
    font-size: 18px;
  }
  .text-provider-name-login {
    border-radius: 0px 0px 19px 19px;
  }
  .promotion_image_pro_games {
    border-radius: 19px 19px 0px 0px;
  }
  .div_promotion_image_pro_games {
    margin: 1rem auto auto auto;
    // padding: 0px 5px;
  }
  .img_game {
    border-radius: 1.2rem;
    // border-radius: 0.8rem;
  }
  .progress_bar_header_game {
    width: 100%;
    height: 9px;
    font-size: 11px;
  }
  .div_dt_game_fire p {
    font-size: 10px;
  }
  .div_dt_game_fire {
    height: 27% !important;
    border-radius: 0 0 1rem 1rem;
  }
  .div_dt_game p {
    font-size: 10px;
  }
  .div_dt_game {
    height: 20% !important;
    border-radius: 0 0 1.5rem 1.5rem;
  }
  .gif_fire {
    width: 29px;
  }
  .fav_game {
    width: 29px;
  }
  .box_ctg_3 .txt {
    bottom: 3px;
    font-size: 16px;
  }
  .box_ctg_2 .txt {
    text-align: center;
    bottom: 3px;
    // margin-left: 10px;
    font-size: 16px;
  }
  .txt_newgame {
    bottom: 1px;
    font-size: 16px;
  }
  .section_content {
    overflow: hidden;
    max-width: 100%;
    margin: 0.5rem 0.5rem !important;
  }
  .newgame_icon_6 {
    left: 20px;
    bottom: 10px;
    margin: auto;
    width: 40px;
  }
  .lotto_js_icon_6 {
    left: 12px;
    bottom: 4px;
    margin: auto;
    width: 40px;
  }
  .newgame_icon_5 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
  }
  .lotto_js_icon_5 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
  }
  .newgame_icon_4 {
    right: 5px;
    bottom: 20px;
    margin: 0 auto;
    width: 60px;
  }
  .lotto_js_icon_4 {
    right: 12px;
    bottom: 20px;
    margin: 0 auto;
    width: 35px;
  }
  .newgame_icon_3 {
    left: 5px;
    bottom: 15px;
    margin: 0 auto;
    width: 60px;
  }
  .lotto_js_icon_3 {
    left: 5px;
    bottom: 20px;
    margin: 0 auto;
    width: 35px;
  }
  .newgame_icon_2 {
    left: 0;
    right: 0;
    bottom: 10px;
    margin: 0 auto;
    width: 60px;
  }
  .lotto_js_icon_2 {
    left: 0;
    right: 0;
    bottom: 23px;
    margin: 0 auto;
    width: 70px;
  }
  .newgame_icon_1 {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90px;
  }
  .lotto_js_icon_1 {
    top: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 125px;
  }
  .gamehot_icon {
    left: 20px;
    top: 0;
    width: 55px;
  }
  .gamehot_left_icon_3 {
    bottom: 20px;
    left: 20px;
    width: 30px;
  }
  .gamehot_left_icon_2 {
    top: 50px;
    left: 10px;
    width: 25px;
  }
  .gamehot_left_icon_1 {
    left: 10px;
    top: 10px;
    width: 40px;
  }
  .gamehot_right_icon_3 {
    right: 20px;
    bottom: 25px;
    width: 30px;
  }
  .gamehot_right_icon_2 {
    top: 40px;
    right: 10px;
    width: 30px;
  }
  .gamehot_right_icon_1 {
    right: 20px;
    top: 10px;
  }
  .gamehot_icon_2 {
    width: 90px;
  }
  .favgame_icon_5 {
    left: 15px;
    bottom: 28px;
    width: 40px;
  }
  .favgame_icon_4 {
    left: 15px;
    top: 5px;
    width: 70px;
  }
  .favgame_icon_3 {
    right: 10px;
    top: 5px;
  }
  .favgame_icon_2 {
    bottom: 12px;
    width: 90px;
  }
  .div_in_a_category span {
    margin-left: 2.5rem !important;
  }
  .div_in_a_category img {
    width: 52px !important;
    margin-right: 0.2rem !important;
    position: absolute !important;
    top: -13px;
    left: -5px !important;
    z-index: 2;
  }
  .div_in_a_category {
    width: 95px !important;
    font-size: 14px !important;
    position: relative !important;
    z-index: 1;
  }
  .item_category_game {
    width: 200px !important;
    padding: 0.7rem 0.3rem !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1100px) {
  .section_content {
    overflow: hidden;
    max-width: 100%;
    margin: 0.5rem 0.5rem !important;
  }
  .div_in_a_category img {
    width: 50px;
  }
  .wrap_category_game {
    width: 100% !important;
    gap: 20px !important;
    flex-wrap: nowrap !important;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    overflow-x: scroll;
    justify-content: flex-start;
    padding-left: 0rem !important;
    height: 100px;
  }
  .box_other_category {
    width: 100%;
    height: auto;
  }
  .div_in_a_category span {
    margin-left: 4rem;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  .gamehot_left_icon_3 {
    left: 30px;
  }
  .gamehot_left_icon_2 {
    top: 60px;
    left: 20px;
    width: 35px;
  }
  .gamehot_left_icon_1 {
    left: 30px;
    top: 20px;
    width: 40px;
  }
  .gamehot_right_icon_3 {
    right: 30px;
  }
  .gamehot_right_icon_2 {
    right: 10px;
  }
  .gamehot_right_icon_1 {
    right: 30px;
  }
  .wrap_category_game {
    gap: 7px !important;
  }
}