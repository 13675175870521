/*=============== SEARCH ===============*/
// .container {
//     height: 100vh;
//     margin-inline: 1.5rem;
//     display: grid;
//     place-items: center;
// }
.search_game {
    position: relative;
    width: 46px;
    height: 46px;
    background-color: #fff;
    box-shadow: 0 4px 24px hsla(222, 68%, 12%, .1);
    border-radius: 4rem;
    padding: 5px;
    overflow: hidden;
    transition: width .5s cubic-bezier(.9, 0, .3, .9);
    &__input {
        border: none;
        outline: none;
        width: calc(100% - 64px);
        height: 100%;
        border-radius: 4rem;
        padding-left: 14px;
        font-size: 14px;
        font-weight: 500;
        opacity: 0;
        pointer-events: none;
        transition: opacity 1.5s;
        &:-webkit-autofill {
            box-shadow: 0 0 0 100px #fff inset;
        }
    }
    &__button {
        width: 56px;
        height: 56px;
        background-color: #000;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
        display: grid;
        place-items: center;
        cursor: pointer;
        transition: transform .6s cubic-bezier(.9, 0, .3, .9);
    }
    &__icon,
    &__close {
        color: var(--white-color);
        font-size: 1.5rem;
        position: absolute;
        transition: opacity .5s cubic-bezier(.9, 0, .3, .9);
    }
    &__close {
        opacity: 0;
    }
}
/* Search animated */
.show-search {
    width: 100%;
    & .search_input {
        opacity: 1;
        pointer-events: initial;
    }
    & .search_button {
        transform: rotate(90deg);
    }
    & .search_icon {
        opacity: 0;
    }
    & .search_close {
        opacity: 1;
    }
}
/*=============== SEARCH ===============*/
// .container {
//     height: 100vh;
//     margin-inline: 1.5rem;
//     display: grid;
//     place-items: center;
// }
.search_game {
    position: relative;
    width: 46px;
    height: 46px;
    background-color: #fff;
    box-shadow: 0 4px 24px hsla(222, 68%, 12%, 0.1);
    border-radius: 4rem;
    padding: 5px;
    overflow: hidden;
    transition: width 0.5s cubic-bezier(0.9, 0, 0.3, 0.9);
}
.search_input {
    border: none;
    outline: none;
    width: calc(100% - 64px);
    height: 100%;
    border-radius: 4rem;
    padding-left: 14px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1.5s;
}
.search_input:-webkit-autofill {
    box-shadow: 0 0 0 100px #fff inset;
}
.search_button {
    width: 36px;
    height: 36px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5px;
    margin: auto;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: transform 0.6s cubic-bezier(0.9, 0, 0.3, 0.9);
}
.search_icon,
.search_close {
    font-size: 1.5rem;
    position: absolute;
    transition: opacity 0.5s cubic-bezier(0.9, 0, 0.3, 0.9);
}
.search_close {
    opacity: 0;
}
/* Search animated */
.show-search {
    width: 100%;
}
.show-search .search__input {
    opacity: 1;
    pointer-events: initial;
}
.show-search .search_button {
    transform: rotate(90deg);
}
.show-search .search_icon {
    opacity: 0;
}
.show-search .search_close {
    opacity: 1;
}
/*=============== BREAKPOINTS ===============*/
/* For medium devices */
@media screen and (min-width: 576px) {
    .show-search {
        width: 450px;
    }
}